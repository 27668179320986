import axios from 'axios';
import debug_ from 'debug';
import dedent from 'dedent';
import { cloneDeep, isArray, isFunction, isNumber, isObject, isString, map } from 'lodash';
import { __NAMESPACE__ } from '~/configs/__NAMESPACE__';
import { __DEV__ } from '~/utils/__DEV__';
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__';
import { __TEST__ } from '~/utils/__TEST__';
const scope = debug_(__NAMESPACE__);
class Debug {
    logger;
    constructor(instance) {
        this.logger = instance;
        this.notifyNamespaceSheetCheat();
    }
    /**
     * - 內建 cloneDeep 所以不會有 pass by references 的問題
     * - 當「未啟用時」不會執行 cloneDeep 所以不消耗性能
     */
    log(msg, ...msgs) {
        if (!this.logger.enabled)
            return;
        msg = typeof msg === 'object' ? cloneDeep(msg) : msg;
        msgs = map(msgs, $msg => (typeof $msg === 'object' ? cloneDeep($msg) : $msg));
        this.logger(msg, ...msgs);
    }
    logIf(ifTruePredicate, msg, ...msgs) {
        if (ifTruePredicate()) {
            this.log.bind(this, msg, ...msgs)();
        }
    }
    enable() {
        const debugString = localStorage.getItem('debug');
        const debugNamespaces = debugString?.split(',') || [];
        debugNamespaces.push(this.logger.namespace);
        debugNamespaces.push(this.logger.namespace + ':*');
        localStorage.setItem('debug', debugNamespaces.join(','));
        console.warn(`%cReload 之後才會生效。請按 ⌘+R 手動 Reload`, 'font-size:16px');
    }
    disable() {
        debug_.disable();
    }
    throwErrorAndLog(msg) {
        this.logger(`[ERROR] ${msg}`);
        throw new Error(msg);
    }
    /** 不要洗版太多次，只提示第一次 */
    isNamespaceSheetCheatNotified = false;
    /** 提示開發者，有可用的 logs 可以開啟瀏覽 */
    notifyNamespaceSheetCheat() {
        const enabled = this.logger.enabled;
        const enabledHelperText = enabled ? '✅ 已啟用' : '🟨 可啟用';
        // eslint-disable-next-line no-console
        if (!__TEST__) {
            console.info(`%c${enabledHelperText} ` +
                `debugAPI.${this.logger.namespace.replace(new RegExp(__NAMESPACE__ + ':', 'i'), '')}.enable()`, `color:#f57105;`);
        }
        this.isNamespaceSheetCheatNotified = true;
    }
}
const entities = {
    chart2: new Debug(scope.extend('chart2')),
    chart3: new Debug(scope.extend('chart3')),
    chart4: new Debug(scope.extend('chart4')),
    datafeed: new Debug(scope.extend('datafeed')),
    datafeed2: new Debug(scope.extend('datafeed2')),
    firebaseMe: new Debug(scope.extend('firebaseMe')),
    fr_events: new Debug(scope.extend('fr_events')),
    fr_instrument: new Debug(scope.extend('fr_instrument')),
    domain: new Debug(scope.extend('domain')),
    fr_me: new Debug(scope.extend('fr_me')),
    fr_serverTime: new Debug(scope.extend('fr_serverTime')),
    FuiSymbolWatch: new Debug(scope.extend('FuiSymbolWatch')),
    ga: new Debug(scope.extend('ga')),
    liteChart: new Debug(scope.extend('liteChart')),
    me: new Debug(scope.extend('me')),
    meRestAPI: new Debug(scope.extend('meRestAPI')),
    pages: new Debug(scope.extend('pages')),
    perf: new Debug(scope.extend('perf')),
    /** 盤勢轉折 */
    pivot: new Debug(scope.extend('pivot')),
    signalr: new Debug(scope.extend('signalr')),
    signalrQuote: new Debug(scope.extend('signalrQuote')),
    signalrV2: new Debug(scope.extend('signalrV2')),
    /** 大戶能量 */
    strength: new Debug(scope.extend('strength')),
    symbol_TX1: new Debug(scope.extend('symbol_TX1')),
    template: new Debug(scope.extend('template')),
    useSoundStore: new Debug(scope.extend('useSoundStore')),
};
export const debugAPI = {
    ...entities,
    ...scope,
    async _sendTextToSlack(text = '') {
        await axios.post(`https://hooks.slack.com/services/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`, JSON.stringify({ text }), {
            headers: {},
            withCredentials: false,
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = undefined;
                    headers['content-type'] = undefined;
                    return data;
                },
            ],
        });
    },
    async sendToSlack(...data) {
        const requests = map(data, async (datum) => {
            let text = ``;
            if (isString(datum)) {
                text = `\`'${datum}'\``;
            }
            else if (isNumber(datum)) {
                text = `\`${datum}\``;
            }
            else if (isArray(datum) || isObject(datum)) {
                text = dedent(`
          \`\`\`
          ${dedent(JSON.stringify(datum, null, 2))}
          \`\`\`
        `);
            }
            else if (isFunction(datum)) {
                text = datum.toString();
            }
            else {
                text = `${text}`;
            }
            return await debugAPI._sendTextToSlack(text);
        });
        await Promise.all(requests);
        await debugAPI._sendTextToSlack('<!-- ----- ----- ----- ----- --end>');
    },
    disableAll() {
        debug_.disable();
    },
};
/* istanbul ignore next */
if (__DEV__ && __IS_CLIENT__) {
    // eslint-disable-next-line no-console
    console.log('%c⭐️ 透過「window.debug.XXX.enable()」來打開日誌系統；記得還要打開 console 面版的篩選器，選取顯示「Verbose」層級（被預設關閉）', 'font-size:16px');
}
