export var AgentProduct;
(function (AgentProduct) {
    AgentProduct["null"] = "null";
    AgentProduct["sungop@web"] = "sungop@web";
    AgentProduct["winner@web"] = "winner@web";
    AgentProduct["futuresai@web"] = "futuresai@web";
    AgentProduct["futuresai@admin"] = "futuresai@admin";
    AgentProduct["futuresai@exchange"] = "futuresai@exchange";
    AgentProduct["futuresai@strategy_center"] = "futuresai@strategy_center";
    AgentProduct["futuresai@promotion"] = "futuresai@promotion";
    AgentProduct["moneycom@web"] = "moneycom@web";
    AgentProduct["good178@web"] = "good178@web";
    AgentProduct["good178@web_stock"] = "good178@web_stock";
    AgentProduct["hyt888@web"] = "hyt888@web";
    AgentProduct["hyt888@web_stock"] = "hyt888@web_stock";
    AgentProduct["weng888@web"] = "weng888@web";
    AgentProduct["weng888@web_stock"] = "weng888@web_stock";
    AgentProduct["asiajye@web"] = "asiajye@web";
    AgentProduct["asiajye@web_stock"] = "asiajye@web_stock";
    AgentProduct["asiajye@web_os_futures"] = "asiajye@web_os_futures";
    AgentProduct["winner98@web_stock"] = "winner98@web_stock";
    AgentProduct["winner98@web"] = "winner98@web";
    AgentProduct["winner98@backtest"] = "winner98@backtest";
    AgentProduct["winner98@crypto"] = "winner98@crypto";
    AgentProduct["blue168@web"] = "blue168@web";
    AgentProduct["sinopac@web"] = "sinopac@web";
    AgentProduct["bole@web_stock"] = "bole@web_stock";
    AgentProduct["san@web"] = "san@web";
    AgentProduct["winner@web_A"] = "winner@web_A";
    AgentProduct["winner@web_B"] = "winner@web_B";
    AgentProduct["winner@web_C"] = "winner@web_C";
    AgentProduct["morerich@web"] = "morerich@web";
    AgentProduct["win168@web"] = "win168@web";
    AgentProduct["richadam@web"] = "richadam@web";
    AgentProduct["richadam@web_stock"] = "richadam@web_stock";
    AgentProduct["ai919@web"] = "ai919@web";
    AgentProduct["goldbrain@web"] = "goldbrain@web";
    AgentProduct["goldbrain@web_stock"] = "goldbrain@web_stock";
    AgentProduct["lung88988@web_stock"] = "lung88988@web_stock";
    AgentProduct["lung88988@web"] = "lung88988@web";
    AgentProduct["fubon@web"] = "fubon@web";
    AgentProduct["futuresai@strategy_center_admin"] = "futuresai@strategy_center_admin";
    AgentProduct["kabujukuTW@web_stock"] = "kabujukuTW@web_stock";
    AgentProduct["ok1788@web_stock"] = "ok1788@web_stock";
    AgentProduct["huanan@web"] = "huanan@web";
    AgentProduct["s178178@web"] = "s178178@web";
    AgentProduct["mvp888@web_stock"] = "mvp888@web_stock";
    AgentProduct["m168168@web_stock"] = "m168168@web_stock";
    AgentProduct["eurex@web"] = "eurex@web";
    AgentProduct["t3j@web"] = "t3j@web";
    AgentProduct["big3@web"] = "big3@web";
    AgentProduct["big3@web_stock"] = "big3@web_stock";
    AgentProduct["more888@web"] = "more888@web";
    AgentProduct["more888@web_stock"] = "more888@web_stock";
    AgentProduct["imoney8888@web_stock"] = "imoney8888@web_stock";
    AgentProduct["yaya168@web_stock"] = "yaya168@web_stock";
    AgentProduct["south@web"] = "south@web";
    AgentProduct["gd1788@web"] = "gd1788@web";
    AgentProduct["jerry597@web_stock"] = "jerry597@web_stock";
    AgentProduct["gorich55688@web_stock"] = "gorich55688@web_stock";
    AgentProduct["daddy960@web_goodway"] = "daddy960@web_goodway";
    AgentProduct["daddy960@web_opkevin"] = "daddy960@web_opkevin";
    AgentProduct["daddy960@web_stocknerve"] = "daddy960@web_stocknerve";
    AgentProduct["daddy960@web_tc1688"] = "daddy960@web_tc1688";
    AgentProduct["daddy960@web_revive"] = "daddy960@web_revive";
    AgentProduct["god@web"] = "god@web";
    AgentProduct["david0705@web"] = "david0705@web";
    AgentProduct["asia71178@web"] = "asia71178@web";
    AgentProduct["stanli@web"] = "stanli@web";
    AgentProduct["datatree@web"] = "datatree@web";
    AgentProduct["rich888@web_stock"] = "rich888@web_stock";
    AgentProduct["win988@web"] = "win988@web";
    AgentProduct["wu5868@web_stock"] = "wu5868@web_stock";
    AgentProduct["pionex@web"] = "pionex@web";
    AgentProduct["sgx@web"] = "sgx@web";
    AgentProduct["edwin@web"] = "edwin@web";
    AgentProduct["freeman@web"] = "freeman@web";
})(AgentProduct || (AgentProduct = {}));
